<template>
  <div class="_about">
    <bannerStatic :num="1" :title="'了解“智能”，见证制造未来'"/>
    <div class="_introduction box-width">
       <div class="_title">
            <h3 class="titleStyle">公司简介</h3>
       </div>
      <a-row :gutter="24">
        <a-col span="16" >
          <div class="_conetent">
           郑州郑大智能科技股份有限公司（简称郑大智能）成立于2016年3月4日，注册资本1000万元，位于郑州市高新技术产业开发区。公司紧盯精密传动系统及半导体材料行业，将自身智能化技术与行业工艺技术紧密融合，面向行业提供更符合行业真实需求、更具有行业应用性的智能工厂整体解决方案，公司以行业专家系统及大数据为核心，形成了成熟的精密传动系统、半导体材料行业的智能制造系统集成及关键软硬件产品。
          </div>
          <div class="_conetent"> 
           公司充分依托河南省智能制造研究院、郑州机械研究所等企业和科研机构，主要成员包括来自郑州大学、北京科技大学、郑州机械研究所等知名学府和相关高新技术研发机构的专家人才，形成了具有自主研发能力和核心技术积累的精英技术团队。
          </div>
        </a-col>
        <a-col span="8"  >
          <img src="@/assets/img/Introduction.jpg" alt="">
        </a-col>
      </a-row>
    </div>
    <div class="center-pic">
      <div class="box-width">
         <img src="@/assets/img/about-center.png" class="right-img" alt="">
          <div class="txt">
         <p>
           <span class="wow fadeIn" data-wow-duration="0.5s" >智能制造</span><br/>
           <b class="wow fadeIn" data-wow-duration="0.5s" data-wow-delay="0.2s">跨入精益管理新时代</b>
         </p>
       </div>
      </div>
       <div class="linear"> </div>
    </div>
    <div class="_experience">
      <div class="box-width _box">
        <div class="_title">
          <h3 class="titleStyle" >发展历程</h3>
        </div>
        <div class="_content" ref="exper">
          <img src="../../assets/img/about-time.png" class="timeImg" />
            <a-timeline mode="alternate" style="overflow:hidden">
              <a-timeline-item v-if="experienceList.length>0"><h3 style="top:0">{{experienceList[0].year}}</h3></a-timeline-item>
              <a-timeline-item v-for="(item,index) in experienceList" :key="item.id" :color="item.isFrist" :class="{'paddingBottom':item.isFrist=='blue'}" v-show="index<showNum" >
                <h3 v-show="item.isLast&&index!=0" >{{item.year}}</h3>
                 <div class="wow fadeIn" data-wow-duration="0.5s"><h4 >{{item.time}}</h4>{{item.content}}</div>
              </a-timeline-item>
            </a-timeline>
            <span class="start" v-show="btnShow" @click="lookmore()">了解更多</span>
        </div>
      </div>
    </div>
    <div class="_honor">
     <div class="box-width">
      <div class="_title">
        <h3  class="titleStyle" >公司荣誉</h3>
      </div>
      <!-- <div class="_swiper">
        <swiper :options="swiperOption">
          <swiper-slide v-for="(item, index) in honorList" :key="`honor-${index}`" class="wow fadeInLeft" :data-wow-delay="`0.${index}s`">
            <img class="item" :data-src="newsImg + item.aboutHonorImageUrl" alt="">
            <div class="_honorTitle" style="margin-top: 50px;">{{ item.aboutHonorName }}</div>
          </swiper-slide>
        </swiper>
        <div class="_NavButton">
          <div class="prev"><a-icon type="left" style="font-size: 30px; color: #4b4a57;" /></div>
          <div class="next"><a-icon type="right" style="font-size: 30px; color: #4b4a57;" /></div>
        </div>
      </div> -->
      <div class="_swiper" @mouseenter="stop('honor')" @mouseleave="start('honor')">
        <div class="_swiperBox" >
          <swiper :options="swiperOption" v-if="honorList.length" ref="honor">
            <swiper-slide
              v-for="(item, index) in honorList"
              :key="`honor-${index}`"
              class="wow fadeInLeft"
              :data-wow-delay="`0.${index}s`"
            >
            <div class="_item">
                <img class="item" :data-src="newsImg + item.aboutHonorImageUrl" alt="">
              </div>
              <div class="_honorTitle" style="margin-top: 50px;color:#333">{{ item.aboutHonorName }}</div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="_NavButton">
          <div class="prev">
            <a-icon type="left" style="font-size: 20px; color: #4b4a57;" />
          </div>
          <div class="next">
            <a-icon type="right" style="font-size: 20px; color: #4b4a57;" />
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import {WOW} from 'wowjs'
import bannerStatic from'@/components/BannerStatic/BannerStatic'
import { getAboutCourses, getAboutHonors } from '@/api/about'
import { newsImg } from '@/config/appSetting'
import moment from 'moment'
export default {
  name: 'About',
  components:{
    bannerStatic
  },
  data() {
    return {
      showNum:5,
      btnShow:true,
      swiperOption: {
        autoplay: {
          delay: 2000
        },
        loop: true,
        slidesPerView: 2,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 20,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        effect: "coverflow",
        coverflowEffect: {
          rotate: 0,
          stretch: 1,
          depth: 400,
          modifier: 0.8,
          slideShadows: false
        },
        navigation: {
          nextEl: ".next",
          prevEl: ".prev"
        }
      },
      experienceList: [],
      honorList: [],
      newsImg: newsImg
    }
  },
  methods: {
    moment,
    getTop (e) {
      let T = e.offsetTop;
      while(e = e.offsetParent) {
        T += e.offsetTop;
      }
      return T;
    },
    lazyLoad (imgs) {
      var H = document.documentElement.clientHeight;
      var S = document.documentElement.scrollTop || document.body.scrollTop;
      for (var i = 0; i < imgs.length; i++) {
        if (H + S > this.getTop(imgs[i])) {
          imgs[i].src = imgs[i].getAttribute('data-src');
        }
      }
    },
    lookmore(){
      this.showNum+=6
      if(this.showNum>this.experienceList.length||this.showNum==this.experienceList.length){ this.btnShow=false}
    },
    stop(id) {
      this.$refs[id].swiper.autoplay.stop();
    },
    start(id) {
      this.$refs[id].swiper.autoplay.start();
    }
  },
  mounted () {
    const _this = this
    getAboutCourses().then(res => {
      if (res.state) {
        res.data.map((item,index)=>{
          let year= _this.moment(item.courseTime,'YYYY/MM/DD').format('YYYY')
          let time= _this.moment(item.courseTime,'YYYY/MM/DD').format('YYYY年M月')
          let islast = true
          if(index>0){
            islast=_this.experienceList[index-1].year==year?false:true
            _this.experienceList[index-1].isFrist=_this.experienceList[index-1].year==year?'grey':'blue'
          }
          _this.experienceList.push(
            {
              year:year,
              time:time,
              content:item.courseContent,
              isLast:islast,
              isFrist:'blue',
              id:item.courseId
            }
          )
        })
      }
    }).then(()=>{
       getAboutHonors().then(res => {
        if (res.state) {
          this.honorList = res.data ? res.data : []
          this.swiperOption.initialSlide = this.honorList.length
        }
      })
       this.$nextTick(() => { 
          let wow = new WOW({
            live: false
          })
          wow.init()
       })
    })
    window.onscroll = function() {
      let imgs = document.querySelectorAll('.item');
      _this.lazyLoad(imgs)
    }
  }
}
</script>

<style lang="less" scoped>
.paddingBottom{
  padding-bottom: 140px;
}
.paddingTop{
  padding-top: 140px;
}
  ._about {
    .titleStyle{
        display: inline-block;
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 24px;
        &::after {
          content: '';
          display: block;
          width: 42px;
          height: 4px;
          background-color: #f9a825;
          border-radius: 2px;
          margin: 10px auto 0; 
        }
    }
    .center-pic{
      height: 415px;
      background-color: #1b77e3;
      margin:60px 0;
      position:relative;
     .linear{
       position: absolute;
       top:0;
       right: 0;
       width: 40%;
       height: 100%;
       background-image: linear-gradient(266deg, #4a90e2 0%, transparent 100%);
      }
     .box-width{
       position:relative;
       .right-img{
         float:left;
         margin-top:-25px;
         margin-left:-20px;
       }
       .txt {
         position: absolute;
         left: 700px;
         top: 120px;
         text-align: left;
         color: #fff;
         font-size: 30px;
         padding-bottom:20px;
         padding-right:60px;
         background:url(../../assets/img/bout-center-line.png) no-repeat bottom right;
         b{
          font-size: 36px;
          font-weight: normal;
          margin-top: 15px;
          display: block;
         }
        }
     }
    }
    ._experience{
      .timeImg{
        margin:30px 0;
      }
      .start{
        position: relative;
        z-index: 2;
        display: block;
        margin-top:20px;
        font-size: 16px;
        color: #3b9dff;
        cursor: pointer;
      }
      .ant-timeline-item {
        width: 900px;
        min-height: 120px;
        margin: 0px auto;
        font-size:15px;
        line-height:26px;
          h4{
            font-size:20px;
            font-weight:500;
            color:#666;
          }
        .ant-timeline-item-content{
          h3 {
              position: absolute;
              top: -100px;
              left: -46px;
              background: #fff;
              padding: 20px 0px;
              color: #fbbc56;
              font-size: 24px;
          }
        }
      }
      .ant-timeline-item:last-child{
        padding-bottom: 0
      }
      .ant-timeline-item-right{
         h3 {
              position: absolute;
              top: -100px;
              right: -40px;
          }
      }
    }
    ._introduction {
      padding-top: 60px;
      padding-bottom: 50px;
      text-align: left;
      color: #333;
      ._title {
        padding-bottom: 30px;
        text-align: center;
      }
      ._conetent {
        padding-right: 50px;
        line-height: 30px;
        font-size: 16px;
        color: #333333;
      }
      img {
        width: 100%;
      }
    }
    
    ._honor {
      background:#f5f8fd;
      padding:0 0 30px;
      margin-top:50px;
      ._title {
        padding-top: 60px;
        padding-bottom: 20px;
        h3 {
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          color: #333333;
          margin-bottom: 5px;
        }
      }
      ._swiper {
        position: relative;
        ._item {
          cursor: pointer;
          display: inline-block;
          margin-bottom: 10px;
          position: relative;
          text-align: center;
          height: 280px;
          img {
            position: absolute;
            box-shadow: 0 2px 12px 0 hsla(0,0%,89%,.69);
            background: #fff;
            padding: 20px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-height: 245px;
          }
        }
        ._honorTitle {
          margin-bottom: 50px;
        }
        .swiper-container {
          margin: 50px 60px 0;
          padding: 0 20px;
          overflow: hidden;
        }
        .prev, .next {
          position: absolute;
          top: 40%;
          transform: translatey(-50%);
          border: 1px solid #bfbfbf;
          padding: 24px 4px;
          cursor: pointer;
          z-index: 1;
        }
        .prev {
          left: 0;
        }
        .next {
          right: 0;
        }
      }
    }
  }

</style>
<style>
 ._about .ant-timeline-item-head-blue {
    width: 20px !important;
    height: 20px !important;
    border-width: 1px !important;
    margin-left: -8.5px !important;
  }
 ._about .ant-timeline-item-head-grey{
    width: 15px !important;
    height: 15px !important;
    border-width: 1px !important;
    margin-left: -6px !important;
  }
  ._about .ant-timeline-item-content{
     padding:0 20px;
   }
</style>